

import { getProductVersions, getProductVersionsPromise } from "../../api";
import { defer } from 'react-router-dom'

export async function loader() {
    const { data } = await getProductVersions();
    return data?.data || [];
}

export function deferredLoader() {

    const fetchPromise = getProductVersionsPromise();

    return defer({
        productVersions: fetchPromise,
    });
}