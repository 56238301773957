
import { useState, useEffect } from "react";

const useAxiosRequest = (apiCall) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);

    async function processRequest() {

        const response = await apiCall();

        if (response.error) {
            setError(response.error);
        } else if (response.data) {
            setData(response.data);
        }

        setLoading(false);
    }

    useEffect(() => {
        processRequest();
    }, []);

    return { data, error, loading };
};

export default useAxiosRequest;