import { resetQueryString } from "../../utils/router";
import { defaultInitialState } from "./context";


export function configuratorReducer(init, state, action) {
    switch (action.type) {

        case 'NEXT_STEP':
            return {
                ...state,
                currentStep: state.currentStep + 1
            };

        case 'PREV_STEP':
            return {
                ...state,
                currentStep: state.currentStep - 1
            };

        case 'SELECT_STEP':
            return {
                ...state,
                currentStep: action.payload
            };
        case 'SELECT_PRODUCT_VERSION':

            const currentStep = state.currentStep + 1;
            return {
                ...state,
                currentStep,
                enabledSteps: [...state.enabledSteps, currentStep],
                choice: {
                    ...state.choice,
                    product: action.payload
                }
            };

        case 'SELECT_MEASURE':
            const currentStepMeasure = state.currentStep + 1;
            return {
                ...state,
                currentStep: currentStepMeasure,
                enabledSteps: [...state.enabledSteps, currentStepMeasure],
                choice: {
                    ...state.choice,
                    ...action.payload
                }
            };

        case 'SELECT_VARIANT':
            const lastStep = state.currentStep + 1;
            return {
                ...state,
                currentStep: lastStep,
                enabledSteps: [...state.enabledSteps, lastStep],
                choice: {
                    ...state.choice,
                    variant: action.payload
                }
            };

        case 'RESET':
            resetQueryString();
            return {
                ...defaultInitialState,
                productVersions: state.productVersions,
            };

        default:
            throw new Error('ConfiguratorReducer invalid reducer action');
    }
}