import { Button, Card, Col, Form, Input, InputNumber, Row, Space, Typography } from "antd";
import React, { useContext } from "react";
import { ConfiguratorContext } from "../context";
import { setQueryStringValuesWithoutNavigation } from "../../../utils/router";
import PageHeader from "../../../components/UI/PageHeader";
import { UndoOutlined } from "@ant-design/icons";
import ProductCard from "../../../components/Product/ProductCard";
import { useTranslation } from "react-i18next";

const SizeForm = ({ onFieldChange, onSubmit, product, type, form, t }) => {

    const minWidth = product[`min_${type}_width`] || 0;
    const maxWidth = product[`max_${type}_width`] || 0;
    const meanWidth = (minWidth + maxWidth) / 2;

    const minHeight = product[`min_${type}_height`] || 0;
    const maxHeight = product[`max_${type}_height`] || 0;
    const meanHeight = (minHeight + maxHeight) / 2;

    return (
        <Form
            layout="horizontal"
            onFinish={onSubmit}
            initialValues={
                {
                    measureType: type,
                    width: meanWidth,
                    height: meanHeight
                }
            }
            form={form}
            onFieldsChange={(changedFields) => {
                onFieldChange({
                    name: changedFields[0]?.name[0],
                    value: changedFields[0]?.value,
                    form: type
                });
            }}
        >

            <Form.Item noStyle name="measureType">
                <Input type="hidden" />
            </Form.Item>

            <Form.Item label={t('configurator.width')} name="width">
                <InputNumber
                    min={minWidth}
                    max={maxWidth}
                    addonAfter="mm"
                />
            </Form.Item>

            <Form.Item label={t('configurator.height')} name="height">
                <InputNumber
                    min={minHeight}
                    max={maxHeight}
                    addonAfter='mm'
                />
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit">
                    {t('configurator.select')}
                </Button>
            </Form.Item>

        </Form>
    )
}

const MeasureStep = () => {

    const { t } = useTranslation();

    const [passageForm] = Form.useForm();

    const [overallForm] = Form.useForm();

    const { state, resetState, selectMeasure } = useContext(ConfiguratorContext);

    const selectedProduct = state.choice.product;

    if (!selectedProduct) {
        resetState()
        return null;
    }

    const onSubmit = (values) => {
        selectMeasure(values);
        setQueryStringValuesWithoutNavigation({
            width: values.width,
            height: values.height,
            type: values.measureType,
            step: 2
        });
    }

    const onFieldChange = ({ name, value, form }) => {

        const widthDiff = selectedProduct.min_overall_width - selectedProduct.min_passage_width;
        const heightDiff = selectedProduct.min_overall_height - selectedProduct.min_passage_height;

        const relativeDiff = name === 'width' ? widthDiff : heightDiff

        if (form === 'passage') {
            overallForm.setFieldsValue({ [name]: value + relativeDiff });
        } else {
            passageForm.setFieldsValue({ [name]: value + relativeDiff });
        }
    }

    return (
        <div>
            <PageHeader
                title={t('configurator.select_measure')}
                subTitle={t('configurator.select_measure_subtitle')}
                extra={<Button icon={<UndoOutlined />} type="default" onClick={() => resetState()}>{t('configurator.cancel')}</Button>}
            />
            <Row gutter={[24, 24]}>

                <Col xs={24} lg={12} xl={14} xxl={16}>
                    <ProductCard product={selectedProduct} />
                </Col>

                <Col xs={24} lg={12} xl={10} xxl={8}>

                    <Space
                        direction="vertical"
                        size="large"
                        style={{ width: "100%" }}
                    >
                        <Card
                            cover={
                                <img
                                    src="/build/images/configurator/passage-size-masonry.jpg"
                                    alt={t('configurator.passage')}
                                />
                            }>
                            <Typography.Title className="mt-1 mb-4" level={4}>{t('configurator.passage')}</Typography.Title>
                            <SizeForm onFieldChange={onFieldChange} form={passageForm} product={selectedProduct} type="passage" onSubmit={onSubmit} t={t} />
                        </Card>

                        <Card
                            cover={
                                <img
                                    src="/build/images/configurator/overall-size-masonry.jpg"
                                    alt={t('configurator.overall')}
                                />
                            }
                        >
                            <Typography.Title className="mt-1 mb-4" level={4}>{t('configurator.overall')}</Typography.Title>
                            <SizeForm onFieldChange={onFieldChange} form={overallForm} product={selectedProduct} type="overall" onSubmit={onSubmit} t={t} />
                        </Card>

                    </Space>
                </Col>
            </Row>
        </div>
    );
}

export default MeasureStep;