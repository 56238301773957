import React from 'react';
import { useRouteError } from 'react-router-dom';
import Page404 from './Page404';
import Page500 from './Page500';
import { Flex } from 'antd';

const ErrorContent = () => {
    const error = useRouteError();

    switch (error.status) {
        case 404:
            return <Page404 />;
        case 500:
            return <Page500 />;
        default:
            return <Page404 />;

    }
}


const ErrorPage = () => {

    return (
        <div className='base-container'>
            <Flex justify='center' align='center' style={{
                height: '90vh',
                width: '100%'
            }}  >
                <div style={{
                    textAlign: 'center'
                }}>
                    <img className='logo' src="/build/images/logo-eclisse.svg" />
                    <ErrorContent />
                </div>

            </Flex>
        </div>

    )
};
export default ErrorPage;