import { useState, useLayoutEffect } from 'react';
import useResizeObserver from './useResizeObserver';

const useWidth = (target) => {
    const [width, setWidth] = useState(undefined);

    useLayoutEffect(() => {
        setWidth(target.current.getBoundingClientRect().width)
    }, [target]);

    useResizeObserver(target.current, (entries) => {
        for (let entry of entries) {
            if (entry.contentRect && entry.contentRect.width) {
                setWidth(entry.contentRect.width)
            }
        }
    });

    return width;
};

export default useWidth;