import React from 'react'
import { Row, Col, Typography, Button } from 'antd'
import './Home.css'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import BoxedContainer from '../../components/UI/BoxedContainer'

const Home = () => {   

    const { t } = useTranslation();

    return (
        <div className="home-container">
            <BoxedContainer>
                <Row>
                    <Col xs={24} lg={{ span: 24, offset: 0 }} xl={{ span: 24, offset: 0 }}>

                        <Row className="pt-5">
                            <Col xs={24} lg={20}>
                                <Typography.Title level={1} className='home-title mb-0'>
                                    <span className='white-color' style={{ fontWeight: 'normal' }}>{t('configurator.welcome_to')}</span><br />                                    
                                    <span className='white-color'>{t('configurator.eclisse_configurator')}!</span>
                                </Typography.Title>
                                                                
                                <Typography.Title level={2} className="font-normal white-color mt-0">
                                    {t('configurator.home_description_1')}<br />
                                    {t('configurator.home_description_2')}
                                </Typography.Title>
                            </Col>
                        </Row>
                        <div className='mt-4'>
                            <div className='start-icons mb-3'>
                                <img src="/build/images/configurator/areo-start-icon.gif" />
                                <img src="/build/images/configurator/arrow-right.svg" />
                                <img className="pdf-icon" src="/build/images/configurator/pdf-icon.svg" />
                            </div>                            

                            <Link to='/configurator'>
                                <Button className='start-button'>
                                    <img src="/build/images/configurator/arrow-right-start.svg" />
                                </Button>
                            </Link>
                        </div>

                    </Col>
                </Row>
            </BoxedContainer>
        </div>
    )
}

export default Home