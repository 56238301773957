import React, { useContext, useState, useRef } from 'react';
import { ConfiguratorContext } from '../context';
import { Alert, Button, Card, Space } from 'antd';
import { Document, Page, pdfjs } from 'react-pdf';
import useWidth from '../../../hooks/useWidth';
import SpinLoader from '../../../components/UI/SpinLoader';
import PageHeader from '../../../components/UI/PageHeader';
import { UndoOutlined, DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

//http-server -p 8889 --cors
//const DEBUG_FILE = ' http://localhost:8889/product-573222-1301006.pdf';


const SummaryStep = () => {

    const { t } = useTranslation();

    const { state, resetState } = useContext(ConfiguratorContext);

    const getPDFFile = () => {

        const qs = new URLSearchParams({
            w: state.choice.width,
            h: state.choice.height,
            d: state.choice.measureType,            
        });

        return `${import.meta.env.VITE_REACT_API_BASE_URL}/technical-specification/${state.choice.variant?.id}?${qs.toString()}`;
    }

    const [loadError, setLoadError] = useState(false);
    const wrapperDiv = useRef(null);
    const width = useWidth(wrapperDiv);

    return (
        <div>
            <PageHeader
                title={t('configurator.summary')}
                subTitle={t('configurator.summary_subtitle')}
                extra={<Button icon={<UndoOutlined />} type="default" onClick={() => resetState()}>{t('configurator.cancel')}</Button>}
            />

            <Card
                title={t('configurator.tech_specs')}
                extra={
                    <Space>
                        <Button disabled={loadError} icon={<EyeOutlined />} key="pdf" type="dashed" href={getPDFFile()} target="_blank" rel="noreferrer">
                            {t('configurator.view_pdf')}
                        </Button>
                        <Button disabled={loadError} icon={<DownloadOutlined />} key="download" type="primary" href={getPDFFile()} target="_blank" download>
                            {t('configurator.download_pdf')}
                        </Button>
                    </Space>
                }
            >
                <div ref={wrapperDiv}>
                    {loadError &&
                        <Alert
                            className="my-4"
                            showIcon
                            type="error"
                            message={t('configurator.error_showing_pdf')}
                        />
                    }

                    <Document
                        noData={null}
                        error={null}
                        loading={<SpinLoader />}
                        file={getPDFFile()}
                        onLoadError={() => {
                            setLoadError(true)
                        }}
                    >
                        <Page className="pdf-render gx-my-4" width={width} pageNumber={1} />
                    </Document>
                </div>
            </Card>
        </div>
    );
}

export default SummaryStep;