import { useEffect } from 'react';

/**
 * Observes a given element using ResizeObserver.
 */
export default function useResizeObserver(
    element,    
    observerCallback,    
) {
    useEffect(() => {
        if (!element || !('ResizeObserver' in window)) {
            return undefined;
        }

        const observer = new ResizeObserver(observerCallback);

        observer.observe(element);        

        return () => {
            observer.disconnect();
        };
    }, [element, observerCallback]);
}