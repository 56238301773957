import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { isDevelopment } from './utils/env';

//console.log('LARAVEL_TRANSLATIONS', LARAVEL_TRANSLATIONS);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: isDevelopment,
    supportedLngs: ['de'],
    fallbackLng: 'de',
    interpolation: {
      escapeValue: false,
      prefix: ':',
      suffix: '',
      prefixEscaped: ':\\b', //prefix + word boundary
      suffixEscaped: '(?:\\b)' //end of word boundary
    },
    // @ts-ignore
    resources: LARAVEL_TRANSLATIONS
  });

export default i18n;