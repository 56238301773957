import React from 'react';
import { Button, Result } from 'antd';
import { Link } from 'react-router-dom';

const Page404 = () => (
    <Result
        status="404"
        title="404"
        subTitle="404_subtitle"
        extra={
            <Link to="/">
                <Button type="primary">Homepage</Button>
            </Link>
        }
    />
);
export default Page404;