import React from "react";
import { Row, Col } from "antd";

const BoxedContainer = ({ children }) => {
    return (
        <Row>
            <Col xs={24} lg={{ span: 18, offset: 3 }} xl={{ span: 16, offset: 4 }}>
                {children}
            </Col>
        </Row>
    );
};

export default BoxedContainer;