export const resetQueryString = () => {
    window.history.replaceState(null, '', window.location.pathname)
}

export const setQueryStringValuesWithoutNavigation = (keyValues) => {
    const currentSearchParams = new URLSearchParams(window.location.search)

    if (!keyValues || Object.keys(keyValues).length === 0) {
        
        resetQueryString();

    } else {

        for (const key in keyValues) {
            currentSearchParams.set(key, keyValues[key]);
        }

        const newUrl = [window.location.pathname, currentSearchParams.toString()]
            .filter(Boolean)
            .join('?')
        window.history.replaceState(null, '', newUrl)

    }
}