import React from 'react';
import { Card, Col, Row, Tabs, Typography, Tooltip, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

type Product = {
    frame_sides_areo: number;
    opening_areo: string;
    min_passage_width?: number;
    max_passage_width?: number;
    min_passage_height?: number;
    max_passage_height?: number;
    min_overall_width?: number;
    max_overall_width?: number;
    min_overall_height?: number;
    max_overall_height?: number;
}

type ProductUserChoice = {
    width: number;
    height: number;
    measureType: string;
}

type ProductCardProps = {
    product: Product;
    actions?: React.ReactNode[];
    userChoice?: ProductUserChoice;
}

const getProductVersionImage = (productVersion: Product) => {

    let openingAreo = productVersion.opening_areo;

    if (productVersion.opening_areo.indexOf('modular') !== -1) {
        openingAreo = 'modular';
    }

    return `/build/images/configurator/areo-tech/${openingAreo}_frames_${productVersion.frame_sides_areo}.svg`;
}

const extractProductVersionTexts = (productVersion: Product, t: TFunction) => {

    let openingSide: string | null = null;

    if (productVersion.opening_areo.indexOf('right') !== -1) {
        openingSide = 'right';
    } else if (productVersion.opening_areo.indexOf('left') !== -1) {
        openingSide = 'left';
    }

    let title = '';

    let titleKey = productVersion.opening_areo;

    titleKey = ['_right', '_left'].reduce((acc, side) => {
        return acc.replace(side, '');
    }, titleKey);

    if (titleKey.indexOf('modular') !== -1) {

        title = t(`products.modular`, { number: titleKey.split('_')[1] });

    } else {
        title = t(`products.${titleKey}`);
    }

    return {
        title: title,
        openingSide: openingSide,
        frames: t('products.frames_number', { count: productVersion.frame_sides_areo }),
    }
}

const OpeningSide = ({ openingSide, t }: { openingSide: string, t: TFunction }) => {

    return (
        <div className='text-center'>
            <Tooltip title={t('configurator.opening')}>
                <img style={{
                    rotate: openingSide === 'right' ? '90deg' : '-90deg'
                }} width={32} src="/build/images/configurator/icon-opening.svg" alt={t('configurator.opening')} />
            </Tooltip>
            <div>{t(`configurator.${openingSide}`)}</div>
        </div>
    )
}

const FrameSides = ({ sides, t }: { sides: number, t: TFunction }) => {
    return (
        <div className='text-center'>
            <Tooltip title={t('products.frame_sides_number')}>
                <img width={32} src="/build/images/configurator/icon-frames.svg" alt={t('products.frame_sides_number')} />
            </Tooltip>
            <div>{sides}</div>
        </div>
    )
}

const ProductCard = ({ product, userChoice, actions }: ProductCardProps) => {

    const { t } = useTranslation();

    const productVersionTexts = extractProductVersionTexts(product, t);

    return (
        <Card
            cover={
                <div>
                    <Typography.Title className='text-center' level={5}>{productVersionTexts.title}</Typography.Title>
                    <img src={getProductVersionImage(product)} alt={productVersionTexts.title} />
                </div>
            }
            actions={actions}>
            <Row gutter={[16, 16]}>
                <Col xs={24} lg={24}>
                    <Space size="large">
                        {productVersionTexts.openingSide && (
                            <OpeningSide openingSide={productVersionTexts.openingSide} t={t} />
                        )}
                        <FrameSides sides={product.frame_sides_areo} t={t} />
                    </Space>
                </Col>

                {userChoice && (
                    <Col xs={24} lg={24}>
                        <Typography.Text strong={true} className='text-uppercase'>
                            {t('configurator.measure_mode')}: {t(`configurator.${userChoice.measureType}`)}
                        </Typography.Text>
                        <br />
                        <span>{t('configurator.width')}: {userChoice.width} mm</span><br />
                        <span>{t('configurator.height')}: {userChoice.height} mm</span>
                    </Col>
                )}

                {!userChoice && (
                    <Col xs={24} lg={24}>
                        <Tabs size='small' tabPosition="top">
                            <Tabs.TabPane tab={t('configurator.passage')} key="1">
                                <span>{t('configurator.width')}: {product.min_passage_width} - {product.max_passage_width} mm</span><br />
                                <span>{t('configurator.height')}: {product.min_passage_height} - {product.max_passage_height} mm</span>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab={t('configurator.overall')} key="2">
                                <span>{t('configurator.width')}: {product.min_overall_width} - {product.max_overall_width} mm</span><br />
                                <span>{t('configurator.height')}: {product.min_overall_height} - {product.max_overall_height} mm</span>
                            </Tabs.TabPane>
                        </Tabs>
                    </Col>
                )}
            </Row>
        </Card>
    );
}

export default ProductCard;