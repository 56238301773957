
import React from "react";
import { Row, Col, Button, Space, Typography } from "antd";
import { LeftCircleFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";
const { Text, Title } = Typography;

const PageHeader = (props) => {

    return (
        <div className="page-heading mb-6">
            <Row align="top" className="mb-1">
                <Col>
                    <Space align="start">
                        {props.backUrl && (
                            <Link to={props.backUrl}>
                                <Button icon={<LeftCircleFilled />} />
                            </Link>
                        )}
                        <div>
                            <Title className="mt-0" level={5}>{props.title}</Title>
                            {props.subTitle && <Text type="secondary">{props.subTitle}</Text>}
                            {props.footer && props.footer}
                        </div>
                    </Space>
                </Col>
                <Col flex="auto" style={{ textAlign: "right" }}>
                    <Space>{props.extra}</Space>
                </Col>
            </Row>            
        </div>
    );
};

export default PageHeader;