
import axios from 'axios';

const client = axios.create({
    baseURL: `${import.meta.env.VITE_REACT_API_BASE_URL}/api/v1`,
    timeout: 1000,
});

export async function getProductVersions() {
    try {
        const response = await client.get('/product-version-groups');
        return { error: null, data: response.data };
    } catch (error) {
        return { error: error.message, data: [] };
    }
}

export async function getProductVersionsPromise() {
    return client.get('/product-version-groups')
        .then(response => {
            return { error: null, data: response.data };
        })
}

export async function getProduct(userChoice) {
    try {
        const response = await client.get('/products', {
            params: {
                type: userChoice.measureType,
                width: userChoice.width,
                height: userChoice.height,
                frame_sides_areo: userChoice.product.frame_sides_areo,
                opening_areo: userChoice.product.opening_areo,
            }
        });
        return { error: null, data: response.data };
    } catch (error) {
        return { error: error.message, data: [] };
    }
}