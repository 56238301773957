import React from 'react';
import { Spin, Flex } from 'antd';

const SpinLoader = ({ minHeight = '60vh' }) => {
    return (
        <Flex style={{ minHeight }} justify="center" align="center">
            <Spin size="large" />
        </Flex>
    )
}

export default SpinLoader;