
import { useEffect, useReducer } from 'react';

export function usePersistedReducer(reducer, initialState, key) {

    const [state, dispatch] = useReducer(reducer, JSON.parse(localStorage.getItem(key)) || initialState);

    useEffect(() => {
        try {
            localStorage.setItem(key, JSON.stringify(state));
        } catch (e) {
            //avoid TypeError: Converting circular structure to JSON
            console.log("Error saving data to localStorage", e);
        }
    }, [key, state, initialState]);

    return [state, dispatch];
}