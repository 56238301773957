import React from 'react';
import { ConfigProvider, Layout } from 'antd'
import { Link, Outlet } from 'react-router-dom';
import { PRIMARY_COLOR, SECONDARY_COLOR } from '../constants';
import './App.css';
import BoxedContainer from '../components/UI/BoxedContainer';

const { Header, Footer, Content } = Layout;

const App = () => {

    return (
        <ConfigProvider
            theme={{
                cssVar: true,
                hashed: false,
                token: {
                    colorPrimary: PRIMARY_COLOR,
                    colorFillSecondary: SECONDARY_COLOR,
                    colorTextSecondary: SECONDARY_COLOR,
                    colorBorderSecondary: SECONDARY_COLOR,
                    colorBgContainer: '#fff',
                    borderRadius: 10,
                    colorTextHeading: PRIMARY_COLOR,
                    colorWarning: SECONDARY_COLOR,
                    fontFamily: 'AvenirNext LT Pro',
                    fontSize: 16,
                    fontSizeSM: 14,
                    fontSizeLG: 18,
                    fontSizeXL: 20,
                    fontWeightStrong: 500,
                    fontSizeHeading1: 48,
                    fontSizeHeading2: 32,
                    fontSizeHeading3: 24,
                    fontSizeHeading4: 20,
                    fontSizeHeading5: 18,
                    fontSizeHeading6: 16,                    
                },
                
                components: {
                    Layout: {
                        headerBg: '#fff',
                    },
                    Button: {
                        defaultActiveColor: SECONDARY_COLOR,
                        defaultActiveBorderColor: SECONDARY_COLOR,
                        defaultHoverBorderColor: SECONDARY_COLOR,
                        defaultHoverColor: SECONDARY_COLOR,
                    },                    
                    
                }
            }}
        >

            <Layout>
                <Header className='header'>
                    <BoxedContainer>
                        <Link to="/"><img className='logo' src="/build/images/logo-eclisse.svg" /></Link>
                    </BoxedContainer>
                </Header>
                <Content className='content'>
                    <Outlet />
                </Content>
                <Footer>
                    <BoxedContainer>
                        ECLISSE GmbH<br />
                        <a href="https://www.eclisse.at">www.eclisse.at</a>
                    </BoxedContainer>
                </Footer>
            </Layout>

        </ConfigProvider >

    );
}

export default App;