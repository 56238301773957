import { Alert, Steps } from "antd";
import React, { useContext, memo } from "react";
import { useLoaderData, Await, useSearchParams, useAsyncValue } from "react-router-dom";
import { ConfiguratorContext, ConfiguratorStateProvider, getInitialState } from "./context";
import ProductVersionStep from "./Steps/ProductVersionStep";
import MeasureStep from "./Steps/MeasureStep";
import AccessoriesStep from "./Steps/AccessoriesStep";
import SummaryStep from "./Steps/SummaryStep";
import './Configurator.css';
import SpinLoader from "../../components/UI/SpinLoader";
import { useTranslation } from "react-i18next";
import BoxedContainer from "../../components/UI/BoxedContainer";

const steps = [
    <ProductVersionStep />,
    <MeasureStep />,
    <AccessoriesStep />,
    <SummaryStep />
]

const ConfiguratorContent = () => {

    const { state, selectStep } = useContext(ConfiguratorContext);

    const { t } = useTranslation();

    return (
        <div className="base-container configurator-container">
            <Steps
                onChange={(value) => {
                    if (value < state.currentStep) {
                        selectStep(value);
                    }
                }}
                initial={0}
                current={state.currentStep}
                className="stepper mb-5"
            >
                <Steps.Step title={t('configurator.product')} />
                <Steps.Step title={t('configurator.measure')}
                    disabled={state.currentStep < 1}
                />
                <Steps.Step title={t('configurator.accessories')}
                    disabled={state.currentStep < 2}
                />
                <Steps.Step title={t('configurator.summary')}
                    disabled={state.currentStep < 3}
                />
            </Steps>

            <div className="configurator-content">
                {steps[state.currentStep]}
            </div>
        </div>
    )
}

const ConfiguratorProvider = () => {

    const productVersions = useAsyncValue();

    const [searchParams] = useSearchParams()

    return (
        <ConfiguratorStateProvider initialState={getInitialState({ productVersions: productVersions.data?.data || [], searchParams })}>
            <ConfiguratorContent />
        </ConfiguratorStateProvider>
    )
}

const Configurator = () => {

    const data = useLoaderData();

    const { t } = useTranslation();

    return (
        <BoxedContainer>
            <div style={{ minHeight: '85vh' }}>
                <React.Suspense
                    fallback={<div><SpinLoader /></div>}
                >
                    <Await
                        resolve={data.productVersions}
                        errorElement={
                            <div className="my-5">
                                <Alert message={t('configurator.error')} description={t('configurator.generic_server_error')} type="error" showIcon />
                            </div>
                        }
                    >
                        <ConfiguratorProvider />
                    </Await>
                </React.Suspense>
            </div>
        </BoxedContainer>

    )
}

export default memo(Configurator)